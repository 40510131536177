import { Link } from "gatsby";
import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image"


const ServiceCardTwo = ({ data }) => {
    const { featuredImage, title, content } = data;

    const [moreInfo, setMoreInfo] = useState(false);

    return (
        <div className="single-service-three">
            <div className="img-box">
                {/* <GatsbyImage
                    alt={featuredImage.node.altText}
                    loading={"lazy"}
                    image={featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                    formats={["auto", "webp", "avif"]}
                    className="float-left"
                    objectFit="contain"
                /> */}

                <div className="w-full" style={{ display: "grid" }}>
                    <GatsbyImage
                        style={{
                            gridArea: "1/1",
                            maxHeight: "250px",
                            width: "100%",
                            // You can set a maximum height for the image, if you wish.
                        }}
                        className="py-16"
                        alt={featuredImage.node.altText}
                        loading={"lazy"}
                        image={featuredImage?.node?.imageFile?.childImageSharp?.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                        objectFit="contain"

                    />
                    <div
                        style={{
                            // By using the same grid area for both, they are stacked on top of each other
                            gridArea: "1/1",
                            position: "relative",
                            // This centers the other elements inside the hero component
                            placeItems: "center",
                            display: "grid",
                        }}
                    >
                        <div className="thm-container text-center">
                        </div>
                    </div>
                </div>

            </div>
            <div className="text-box hvr-bounce-to-bottom w-full cursor-pointer">
                <div onClick={() => setMoreInfo(!moreInfo)}>
                    <h3 className="text-center flex items-center w-full justify-center" style={{ minHeight: "56px" }}>{title}</h3>
                </div>
                {moreInfo && (
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        <div>
                            <p className="cursor-pointer text-center" onClick={() => setMoreInfo(false)}>Ελαχιστοποίηση</p>
                        </div>
                    </div>
                )}

                {!moreInfo && (
                    <p className="cursor-pointer text-center" onClick={() => setMoreInfo(true)}>Περισσότερα</p>
                )}

                {/* <Link to={url} className="read-more fas fa-angle-right"></Link> */}
            </div>
        </div>
    );
};

export default ServiceCardTwo;
