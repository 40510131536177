import React from "react";
import { Col, Row } from "react-bootstrap";
import { ServiceOneData } from "@/data";
import ServiceCardTwo from "@/components/service-card-two";

const ServiceOne = (props) => {
    let data = props.data
    return (
        <section className="service-style-three sec-pad">
            { data.children?.edges?.map((cat, i) => (
                <div key={`cat-${i}`} className="thm-container">
                    <h2 className="pb-4 text-black">{cat.node.name}</h2>
                    <div className="flex flex-wrap">
                        {cat.node.posts.edges.map((post, index) => (
                            <div className="w-full md:w-1/3 lg:w-1/4 px-2" key={index}>
                                <ServiceCardTwo data={post.node} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}

        </section>
    );
};

export default ServiceOne;
