import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";

import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import ServiceOne from "@/components/service-one";


import { graphql } from "gatsby"


const Serving = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://topaper.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://topaper.gr${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <MenuContextProvider>
            <Layout serving={data.categories} metaData={constructMetaData(data.category, props.pageContext.currentPage)} >
                <HeaderOne serving={data.categories} />
                <PageHeader title={data.category.name} name={data.category.name} />
                <ServiceOne data={data.category} />
                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default Serving;


export const pageQuery = graphql`query GET_SERVING($id: ID!) {
    wpgraphql {
        category(id: $id) {
            name
            slug
            id
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }
            children{
                edges{
                    node{
                        name
                        slug
                        posts (first: 200, where: {orderby: {field: DATE, order: ASC}}){
                            edges{
                                node{
                                    title
                                    content
                                    featuredImage{
                                        node{
                                            sourceUrl
                                            altText
                                            imageFile {
                                                childImageSharp {
                                                    gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        categories (first:500) {
            edges {
              node {
                name
                description
                slug
                categoryPageH1{
                  categoryImage{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                  }
                }
                children {
                  edges {
                    node {
                      name
                      posts {
                        edges {
                          node {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  
    }
}
`